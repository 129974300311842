import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';

export default class Package extends BaseModel {

    static entity = 'packages'
    static primaryKey = 'code'

    static fields() {
        return {
            code: this.string(''),
            title: this.string(''),
            packageType: this.string(''),
            description: this.string(''),
            price: this.string(''),
            priceNet: this.string(''),
            packageData: this.attr([]),
            isCustomPackage: this.boolean(true),
        }
    }

    static async apiFetchAll() {
        const query = gql`
            query packages {
                packages {
                    code
                    packageType
                    title
                    description
                    price
                    priceNet
                    packageData
                    isCustomPackage
                }
            }
        `
        const res = await window.$graphQLQuery(query, {})
        if (res && res.packages) {
            useRepo(this).save(res.packages)
        }
        return res
    }

}
