import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import DomainDnsRecord from './DomainDnsRecord'
import Domain from './Domain'
import Package from './Package'
import Project from './Project'
import Task from './Task'
import VirtualServerBackup from './VirtualServerBackup'
import VirtualServerBasicAuth from './VirtualServerBasicAuth'
import VirtualServerCronjob from './VirtualServerCronjob'
import VirtualServerDatabase from './VirtualServerDatabase'
import VirtualServerEmail from './VirtualServerEmail'
import VirtualServerEmailList from './VirtualServerEmailList'
import VirtualServerFtpAccount from './VirtualServerFtpAccount'
import VirtualServerSubdomain from './VirtualServerSubdomain'

export default class VirtualServer extends BaseModel {

    static entity = 'virtualservers'

    static fields() {
        return {
            id: this.uid(),
            domainName: this.string(''),
            domainNameIdna: this.string(''),
            domainId: this.number(null),
            domain: this.belongsTo(Domain, 'domainId'),
            serverWeb: this.string(''),
            serverWebIp: this.string(''),
            serverEmail: this.string(''),
            serverEmailIp: this.string(''),
            dkim: this.attr({}),
            packageId: this.attr(''),
            package: this.belongsTo(Package, 'packageId'),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            projectId: this.number(0),
            project: this.belongsTo(Project, 'projectId'),
            serverBackups: this.hasMany(VirtualServerBackup, 'virtualServerId'),
            basicauths: this.hasMany(VirtualServerBasicAuth, 'virtualServerId'),
            cronjobs: this.hasMany(VirtualServerCronjob, 'virtualServerId'),
            databases: this.hasMany(VirtualServerDatabase, 'virtualServerId'),
            emails: this.hasMany(VirtualServerEmail, 'virtualServerId'),
            emaillists: this.hasMany(VirtualServerEmailList, 'virtualServerId'),
            ftpAccounts: this.hasMany(VirtualServerFtpAccount, 'virtualServerId'),
            subdomains: this.hasMany(VirtualServerSubdomain, 'virtualServerId'),

            sshStarted: this.string(null),
            sshHost: this.string(''),
            sshUsername: this.string(''),
            sshPort: this.number(0),
            sshKeyCount: this.number(0),

            tasks: this.morphMany(Task, 'objectId', 'contentType')
        }
    }

    static async apiFetchSingle(virtualServerId) {
        const query = gql`
            query getVirtualServer($id: Int!) {
                virtualServer(id: $id) {
                    id
                    domainName
                    domainNameIdna
                    domain{
                        id
                    }
                    serverWeb
                    serverWebIp
                    serverEmail
                    serverEmailIp
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                    sshStarted
                    sshHost
                    sshUsername
                    sshPort
                    sshKeyCount
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(virtualServerId) })
        if (res && res.virtualServer) {
            useRepo(this).save(res.virtualServer)
        }
        return res
    }

    static async apiFetchAll(projectId) {
        if (!projectId) {
            return []
        }
        const query = gql`
            query getVirtualServers($projectId: Int) {
                virtualServers(projectId: $projectId) {
                    id
                    domainName
                    domainNameIdna
                    domain{
                        id
                    }
                    serverWeb
                    serverWebIp
                    serverEmail
                    serverEmailIp
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                    sshStarted
                    sshHost
                    sshUsername
                    sshPort
                    sshKeyCount
                }
            }
        `
        const res = await window.$graphQLQuery(query, { projectId: parseInt(projectId) })
        if (res && res.virtualServers) {
            useRepo(this).save(res.virtualServers)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServer($virtualServer: CreateVirtualServerInput!) {
                createVirtualServer(virtualServer: $virtualServer){
                    id
                    domainName
                    domainNameIdna
                    domain{
                        id
                    }
                    serverWeb
                    serverWebIp
                    serverEmail
                    serverEmailIp
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project{
                        id
                    }
                    created
                    updated
                    deleted
                    sshStarted
                    sshHost
                    sshUsername
                    sshPort
                    sshKeyCount
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServer) {
            await useRepo(this).save(res.createVirtualServer)
            DomainDnsRecord.refresh(null, res.createVirtualServer.id)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServer($virtualServer: UpdateVirtualServerInput!, $id: Int!) {
                updateVirtualServer(virtualServer: $virtualServer, id: $id){
                    id
                    domainName
                    domainNameIdna
                    domain{
                        id
                    }
                    serverWeb
                    serverWebIp
                    serverEmail
                    serverEmailIp
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project{
                        id
                    }
                    created
                    updated
                    deleted
                    sshStarted
                    sshHost
                    sshUsername
                    sshPort
                    sshKeyCount
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServer) {
            await useRepo(this).save(res.updateVirtualServer)
        }
        return res
    }

    static async apiDelete(virtualServerId) {
        const query = gql`
            mutation deleteVirtualServer($id: Int!) {
                deleteVirtualServer(id: $id){
                    id
                    domainName
                    domainNameIdna
                    domain{
                        id
                    }
                    serverWeb
                    serverWebIp
                    serverEmail
                    serverEmailIp
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project{
                        id
                    }
                    created
                    updated
                    deleted
                    sshStarted
                    sshHost
                    sshUsername
                    sshPort
                    sshKeyCount
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(virtualServerId) })
        if (res && res.deleteVirtualServer) {
            DomainDnsRecord.refresh(null, parseInt(virtualServerId))

            await useRepo(this).save(res.deleteVirtualServer)
        }
        return res
    }

    /* email DKIM setting */
    static async apiCreateDkim(virtualServerId) {
        const query = gql`
            mutation createVirtualServerDkim($id: Int!) {
                createVirtualServerDkim(id: $id){
                    id
                    domainName
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(virtualServerId) })
        if (res && res.createVirtualServerDkim) {
            await useRepo(this).save(res.createVirtualServerDkim)

            // refresh all dnsrecords
            let virtualServer = useRepo(this).find(virtualServerId);
            if (virtualServer && virtualServer.domainId) {
                useRepo(DomainDnsRecord).where('type', 'TXT').where('data', (value) => { return value.includes('v=DKIM1;') }).delete()
                DomainDnsRecord.apiFetchAll(virtualServer.domainId)
            }
        }
        return res
    }

    /* email DKIM setting */
    static async apiDeleteDkim(virtualServerId) {
        const query = gql`
            mutation deleteVirtualServerDkim($id: Int!) {
                deleteVirtualServerDkim(id: $id){
                    id
                    domainName
                    dkim {
                        dnsName,
                        dnsTtl,
                        dnsType,
                        dnsData
                    }
                    package {
                        code
                        packageType
                        title
                        description
                        price
                        priceNet
                        packageData
                        isCustomPackage
                    }
                    project{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(virtualServerId) })
        if (res && res.deleteVirtualServerDkim) {
            await useRepo(this).save(res.deleteVirtualServerDkim)

            // refresh all dnsrecords
            let virtualServer = useRepo(this).find(virtualServerId);
            if (virtualServer && virtualServer.domainId) {
                useRepo(DomainDnsRecord).where('type', 'TXT').where('data', (value) => { return value.includes('v=DKIM1;') }).delete()
                DomainDnsRecord.apiFetchAll(virtualServer.domainId)
            }
        }
        return res
    }

}
