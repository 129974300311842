import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'
import Task from './Task'

export default class VirtualServerEmail extends BaseModel {

    static entity = 'virtualServerEmails'

    static fields() {
        return {
            id: this.uid(),
            address: this.string(''),
            comment: this.string(''),
            spamFilter: this.boolean(false),
            forwardTo: this.attr([]),
            leaveCopy: this.boolean(false),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            arActive: this.boolean(false),
            arStarted: this.string(null),
            arEnded: this.string(null),
            arSubject: this.string(''),
            arContent: this.string(''),
            arInterval: this.number(43200),
            virtualServerId: this.number(0),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId'),

            tasks: this.morphMany(Task, 'objectId', 'contentType')
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerEmails($virtualServerId: Int) {
                virtualServerEmails(virtualServerId: $virtualServerId) {
                    id
                    address
                    comment
                    spamFilter
                    forwardTo
                    leaveCopy
                    arActive
                    arStarted
                    arEnded
                    arSubject
                    arContent
                    arInterval
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerEmails) {
            useRepo(this).save(res.virtualServerEmails)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerEmail($email: CreateEmailInput!) {
                createVirtualServerEmail(email: $email){
                    id
                    address
                    comment
                    spamFilter
                    forwardTo
                    leaveCopy
                    arActive
                    arStarted
                    arEnded
                    arSubject
                    arContent
                    arInterval
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerEmail) {
            await useRepo(this).save(res.createVirtualServerEmail)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerEmail($email: UpdateEmailInput!, $id: Int!) {
                updateVirtualServerEmail(email: $email, id: $id){
                    id
                    address
                    comment
                    spamFilter
                    forwardTo
                    leaveCopy
                    arActive
                    arStarted
                    arEnded
                    arSubject
                    arContent
                    arInterval
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerEmail) {
            await useRepo(this).save(res.updateVirtualServerEmail)
        }
        return res
    }

    static async apiDelete(emailId) {
        const query = gql`
            mutation deleteVirtualServerEmail($id: Int!) {
                deleteVirtualServerEmail(id: $id){
                    id
                    address
                    comment
                    spamFilter
                    forwardTo
                    leaveCopy
                    arActive
                    arStarted
                    arEnded
                    arSubject
                    arContent
                    arInterval
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(emailId) })
        if (res && res.deleteVirtualServerEmail) {
            await useRepo(this).save(res.deleteVirtualServerEmail)
        }
        return res
    }
}
