import { defineNuxtPlugin } from '#app';
import { useStore } from '@/store/index';
import { useRepo } from 'pinia-orm';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import moment from 'moment';
import Team from '@/data/models/Team';

export default defineNuxtPlugin(nuxtApp => {
    /*
    Global methods
    */
    console.log('Init plugins/nodeup-utils.js')

    const store = useStore();

    const runWhenRefAvailable = async function (params) {
        /* Fix for when calling "return this.$refs.form.validate()" does not work */
        /* Problem is because of css transitions, and $refs get filled later */
        /* this method lets us wait till $refs appear or sanity check 5 sec is full */

        /* /account -> edit team (teamAddEdit) -> works first time, fails second time (clearValidation() === null) */
        /* seems like form component reload issue - is fixed after 50ms */
        let lifecycles = 0
        while ((typeof params.context.$refs[params.ref] === 'undefined' || params.context.$refs[params.ref] === null) && lifecycles < 100) {
            await new Promise(r => setTimeout(r, 50))
            lifecycles += 1
        }
        if (lifecycles < 100) {
            if (params.params) {
                return params.context.$refs[params.ref][params.method](params.params)
            } else {
                return params.context.$refs[params.ref][params.method]()
            }
        }
    }

    const niceDateTime = function (inDate) {
        if (!inDate)
            return ""
        return moment(inDate).format("D MMM YYYY, HH:mm:ss")
    }

    const niceDate = function (inDate) {
        if (!inDate)
            return ""
        return moment(inDate).format("D MMM YYYY")
    }

    const getFullMonthsDays = function (params) {
        // sample test data
        // 01.11.2022 - 31.12.2022
        // 11.10.2022 - 20.10.2022
        // 01.10.2022 - 20.10.2022
        // 20.12.2022 - 31.12.2022
        // 20.12.2022 - 03.01.2023
        // 23.11.2022 - 27.02.2023

        // in user local time, so we can ignore timzeone difference
        params.startDate = moment(params.startDate)
        params.endDate = moment(params.endDate)
        if (params.startDate > params.endDate) {
            [params.startDate, params.endDate] = [params.endDate, params.startDate]
        }
        var days = 0
        var months = 0
        var hasPartialFirstMonth = (params.startDate.date() == 1) ? false : true
        while (params.startDate <= params.endDate) {
            days += 1
            // last day of month
            if (params.startDate.date() == params.startDate.daysInMonth()) {
                // reduce full months from days
                if (!hasPartialFirstMonth || months > 0) {
                    days -= params.startDate.daysInMonth()
                }
                months += 1
            }
            params.startDate.add(moment.duration(1, 'd'))
        }
        // ignore initial partial month change
        if (hasPartialFirstMonth) {
            months -= 1
            months = Math.max(0, months)
        }

        var res = ''
        if (months) {
            res += months + ' ' + (months == 1 ? 'month' : 'months')
        }
        if (days) {
            if (res) {
                res += ', '
            }
            res += days + ' ' + (days == 1 ? 'day' : 'days')
        }
        return res
    }

    const convertUnitsToDaysHours = function (quantity, unit) {
        if (unit === 'hours') {
            const days = Math.floor(quantity / 24);
            const remainingHours = quantity % 24;

            let result = '';
            if (days > 0) {
                result += days + ' ' + (days === 1 ? 'day' : 'days');
            }
            if (remainingHours > 0) {
                if (result) {
                    result += ' ';
                }
                result += remainingHours + ' ' + (remainingHours === 1 ? 'hour' : 'hours');
            }
            return result;
        } else {
            // Return quantity with its original unit (e.g., months)
            return quantity + ' ' + (quantity === 1 ? unit.slice(0, -1) : unit);
        }
    };

    const getUTCNow = function () {
        return new Date().getTime() - (new Date().getTimezoneOffset() * 60000)
    }

    const isCardExpired = function (cardExpires) {
        return new Date(2000 + parseInt(cardExpires.slice(-2)), parseInt(cardExpires.slice(0, 2)) + 1).getTime() < new Date().getTime()
    }

    const isDateExpired = function (dateIn) {
        if (typeof (dateIn) == 'string') {
            dateIn = Date.parse(dateIn)
        }
        return dateIn && dateIn < getUTCNow()
    }

    const isDateExpiring = function (dateIn) {
        /* Expires in 90 days */
        if (typeof (dateIn) == 'string') {
            dateIn = Date.parse(dateIn)
        }
        return dateIn && dateIn > getUTCNow() && dateIn < (getUTCNow() + 1000 * 60 * 60 * 24 * 90)
    }

    const isDateActive = function (dateIn) {
        return !isDateExpiring(dateIn) && !isDateExpired(dateIn)
    }

    const preventAutocomplete = function () {
        setTimeout(function () {
            document.querySelectorAll('[autocomplete="off"]').forEach(element => {
                element.setAttribute('readonly', 'readonly')
                element.style.backgroundColor = 'inherit'
                setTimeout(function () { element.removeAttribute('readonly') }, 500)
            })
        }, 10)
    }

    const isElementScrollable = async function (clsName) {
        let lifecycles = 0
        while (true) {
            if (document.getElementsByClassName(clsName).length && parseInt(document.getElementsByClassName(clsName)[0].clientHeight) > 0) {
                break
            }
            if (lifecycles == 100) {
                break
            }
            await new Promise(r => setTimeout(r, 50))
            lifecycles += 1
        }
        if (lifecycles < 100) {
            let el = document.getElementsByClassName(clsName)
            if (el && el[0]) {
                return (parseInt(el[0].scrollHeight) > parseInt(el[0].clientHeight))
            }
        }
        return false
    }

    const validateEmail = function (email) {
        return (/^(?:[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zäöüõšž0-9](?:[a-zäöüõšž0-9-]*[a-zäöüõšž0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(email))
    }

    const validateEmailExtended = function (email) {
        return (/^([a-zA-ZäöüõšžÄÖÜÕŠŽ0-9-\ \(\)]{0,64}) ?<(?:[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zäöüõšž0-9](?:[a-zäöüõšž0-9-]*[a-zäöüõšž0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])>$/.test(email))
    }

    const validateDomain = function (domain) {
        return (/^(?:(?:[a-zäöüõšž0-9](?:[a-zäöüõšž0-9-]*[a-zäöüõšž0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(domain))
    }

    const validateIPV4 = function (ip) {
        return (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip))
    }

    const validateIPV4Private = function (ip) {
        return (/(^127\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$)|(^10\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$)|(^172\.1[6-9]{1}[0-9]{0,1}\.[0-9]{1,3}\.[0-9]{1,3}$)|(^172\.2[0-9]{1}[0-9]{0,1}\.[0-9]{1,3}\.[0-9]{1,3}$)|(^172\.3[0-1]{1}[0-9]{0,1}\.[0-9]{1,3}\.[0-9]{1,3}$)|(^192\.168\.[0-9]{1,3}\.[0-9]{1,3}$)/.test(ip))
    }

    const downloadBinaryFile = function (params) {
        var byteCharacters = atob(params.binaryFile)
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        // VAR 1
        // var file = new Blob([byteArray], { type: params.mimeType + ';base64' })
        // var fileURL = URL.createObjectURL(file)
        // window.open(fileURL)

        // VAR 2
        var hiddenElement = document.createElement('a')
        hiddenElement.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: params.mimeType + ';base64' })
        )
        hiddenElement.target = '_blank'
        hiddenElement.download = params.filename
        hiddenElement.click()
    }

    const downloadTextFile = function (params) {
        var hiddenElement = document.createElement('a')
        hiddenElement.href = window.URL.createObjectURL(
            new Blob([params.contents], { type: params.mimeType })
        );
        hiddenElement.target = '_blank'
        hiddenElement.download = params.filename
        hiddenElement.click()
    }

    const copyToClipboard = function (params) {
        try {
            navigator.clipboard.writeText(params.text);
        } catch (e) {
            // Cannot use navigator.clipboard on a non HTTPS site.
        }
        store.setToastMessage({ message: "Copied '" + params.text + "'.", duration: 2000 })
    }

    const generatePassword = function (params) {
        if (params === undefined) {
            params = {}
        }
        params.length = params.length || 11
        params.charset = params.charset || 'a-z,A-Z,0-9,#'

        let charArray = params.charset.split(',')
        let charSet = ''
        let password = ''

        if (charArray.indexOf('a-z') >= 0) {
            charSet += 'abcdefghijkmnpqrstuvwxyzabcdefghijkmnpqrstuvwxyz' // removed o, l and doubled chance
        }
        if (charArray.indexOf('A-Z') >= 0) {
            charSet += 'ABCDEFGHJKLMNPQRSTUVWXYZABCDEFGHJKLMNPQRSTUVWXYZ' // removed I, O and doubled chance
        }
        if (charArray.indexOf('0-9') >= 0) {
            charSet += '123456789123456789' // removed 0 and doubled chance
        }
        if (charArray.indexOf('#') >= 0) {
            charSet += '![]{}()%&*#<>@'
        }

        // test against our minimum password format
        while (!/^(?=.{8,32}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(password)) {
            password = ''
            for (let i = 0; i < params.length; i++) {
                password += charSet.charAt(Math.floor(Math.random() * charSet.length))
            }
        }
        return password
    }

    const googleDns = async function (hostname, type = '') {
        if (!hostname) {
            return new Promise(function (resolve, reject) { reject('Missing hostname.') })
        }
        if (type) {
            type = '&type=' + type
        }
        let hostname_idna = toASCII(hostname)
        return await fetch('https://dns.google/resolve?name=' + hostname_idna + type, { cache: "no-store" })
            .then(response => response.json())
            .then(data => data.Answer)
    }

    const toASCII = function (hostname) {
        try {
            // Create a new URL object, using an arbitrary protocol and the hostname
            // The URL object will automatically convert the hostname to its ASCII (Punycode) representation
            const url = new URL(`http://${hostname}`);
            return url.hostname; // Access the Punycode-encoded hostname
        } catch (error) {
            console.error("Invalid hostname", error);
            return null; // or handle the error as appropriate for your application
        }
    }

    const isDomainRegistered = async function (hostname) {
        return await googleDns(hostname, 'NS').then(res => res && !!res.length)
    }

    const hasOurNameserver = async function (hostname) {
        return await googleDns(hostname, 'NS').then(res => res && res.length && res.some((r) => ['ns1.nss.ee.', 'ns2.nss.ee.', 'ns1.nodeup.io.', 'ns2.nodeup.io.'].includes(r.data)))
    }

    const hasOtherMX = async function (params) {
        return await googleDns(params.hostname, 'MX').then(res => !!res && !!res.length && res[0].data.slice(-8) != ".nss.ee." && res[0].data.slice(-11) != ".nodeup.io." && res[0].data != params.serverEmail)
    }

    const getDomainNS = async function (hostname) {
        return await googleDns(hostname, 'NS')
    }

    const getHostIp = async function (hostname) {
        return await googleDns(hostname).then(res => res && res.length && res[0].data || '')
    }

    // <v-autocomplete v-autocomplete-rm-arrow-focus variant="underlined" flat :filter="$filterPhonePrefix" ...>
    const filterPhonePrefix = function (itemText, queryText, item) {
        return ((item.raw.country + ' ' + item.raw.phonePrefix).toLowerCase().indexOf(queryText.toLowerCase()) > -1)
    }

    const isAdmin = function () {
        var teamData = useRepo(Team).find(store.team)
        return teamData && ['OWNER', 'ADMIN'].includes(teamData.myRole)
    }

    const isOwner = function () {
        var teamData = useRepo(Team).find(store.team)
        return teamData && teamData.myRole == 'OWNER'
    }

    const getPersonalCodeCountry = function () {
        const items = [{
            title: 'Estonia',
            value: 'EE'
        }, {
            title: 'Latvia',
            value: 'LV'
        }, {
            title: 'Lithuania',
            value: 'LT'
        }, {
            title: 'Belgium',
            value: 'BE'
        }]
        return items
    }

    const checkWebAuthnAvailability = function () {
        try {
            const isAvailable = browserSupportsWebAuthn();
            if (!isAvailable) {
                throw new Error('Passkey is not available');
            }
            console.log('Passkey is available');
        } catch (error) {
            console.error('Error checking Passkey availability:', error);
            const store = useStore();
            store.setGlobalError('Passkey error detected.')
        }
    }

    const checkWebEidAvailability = async function () {
        try {
            const isAvailable = await window.webeid.status();
            if (!isAvailable) {
                throw new Error('Web eID is not available');
            }
            console.log('Web eID is available');
        } catch (error) {
            console.error('Error checking Web eID availability:', error);
            const store = useStore();
            store.setGlobalError('ID-card Web eID error detected.')
        }
    }

    const base64urlToString = function (base64url) {
        const padding = '===='.slice((base64url.length % 4) || 4);
        const base64 = (base64url + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');
        const rawData = atob(base64);
        return rawData;
    }

    nuxtApp.provide('isElementScrollable', isElementScrollable);
    nuxtApp.provide('setToastMessage', params => store.setToastMessage(params));
    nuxtApp.provide('fadeToastMessage', params => store.fadeToastMessage(params));
    nuxtApp.provide('clearToastMessage', params => store.clearToastMessage(params));
    nuxtApp.provide('runWhenRefAvailable', runWhenRefAvailable);
    nuxtApp.provide('niceDate', niceDate);
    nuxtApp.provide('niceDateTime', niceDateTime);
    nuxtApp.provide('getFullMonthsDays', getFullMonthsDays);
    nuxtApp.provide('convertUnitsToDaysHours', convertUnitsToDaysHours);
    nuxtApp.provide('getUTCNow', getUTCNow);
    nuxtApp.provide('isCardExpired', isCardExpired);
    nuxtApp.provide('isDateExpired', isDateExpired);
    nuxtApp.provide('isDateExpiring', isDateExpiring);
    nuxtApp.provide('isDateActive', isDateActive);
    nuxtApp.provide('preventAutocomplete', preventAutocomplete);
    nuxtApp.provide('validateEmail', validateEmail);
    nuxtApp.provide('validateEmailExtended', validateEmailExtended);
    nuxtApp.provide('validateDomain', validateDomain);
    nuxtApp.provide('validateIPV4', validateIPV4);
    nuxtApp.provide('validateIPV4Private', validateIPV4Private);
    nuxtApp.provide('downloadBinaryFile', downloadBinaryFile);
    nuxtApp.provide('downloadTextFile', downloadTextFile);
    nuxtApp.provide('copyToClipboard', copyToClipboard);
    nuxtApp.provide('generatePassword', generatePassword);
    nuxtApp.provide('isDomainRegistered', isDomainRegistered);
    nuxtApp.provide('hasOurNameserver', hasOurNameserver);
    nuxtApp.provide('hasOtherMX', hasOtherMX);
    nuxtApp.provide('getHostIp', getHostIp);
    nuxtApp.provide('getDomainNS', getDomainNS);
    nuxtApp.provide('filterPhonePrefix', filterPhonePrefix);
    nuxtApp.provide('isAdmin', isAdmin);
    nuxtApp.provide('isOwner', isOwner);
    nuxtApp.provide('getPersonalCodeCountry', getPersonalCodeCountry);
    nuxtApp.provide('checkWebAuthnAvailability', checkWebAuthnAvailability);
    nuxtApp.provide('checkWebEidAvailability', checkWebEidAvailability);
    nuxtApp.provide('base64urlToString', base64urlToString);
});
