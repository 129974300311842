import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'
import VirtualServerEmail from './VirtualServerEmail'
import VirtualServerSubdomain from './VirtualServerSubdomain'
import Vm from './Vm'
import VpnUser from './VpnUser'

// Define a mapping from backend to frontend content types
const contentTypeMapping = {
    'subdomain': 'virtualServerSubdomains',
    'virtualserver': 'virtualservers',
    'vm': 'vms',
    'vpnuser': 'vpnUsers',
    'email': 'virtualServerEmails',
}

export default class Task extends BaseModel {
    static entity = 'tasks'

    static fields() {
        return {
            id: this.uid(),
            taskType: this.string(''),
            taskProgress: this.number(null),
            estimatedDuration: this.number(3),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),

            // Define a polymorphic relation
            contentType: this.attr(''), // Type of the related model (e.g., 'virtualServerFtpAccounts')
            objectId: this.attr(null), // ID of the related model
            related: this.morphTo([VirtualServer, VirtualServerEmail, VirtualServerSubdomain, Vm, VpnUser], 'objectId', 'contentType')
        }
    }

    static async apiFetchAll() {
        const query = gql`
            query getTasks {
                tasks {
                    id
                    taskType
                    taskProgress
                    estimatedDuration
                    created
                    updated
                    deleted
                    contentType
                    objectId
                }
            }
        `
        const res = await window.$graphQLQuery(query, {})
        if (res && res.tasks) {
            // Map backend contentType to frontend model names
            // eg 'ftpaccount' -> virtualServerFtpAccount
            const tasksWithMappedTypes = res.tasks.map(task => ({
                ...task,
                objectId: parseInt(task.objectId), // uuid may not work?
                contentType: contentTypeMapping[task.contentType] || task.contentType,
            }))
            useRepo(this).save(tasksWithMappedTypes)
        }
        return res
    }

    static async apiFetchSingle(taskId) {
        const query = gql`
            query getTasks ($id: Int!) {
                task(id: $id) {
                    id
                    taskType
                    taskProgress
                    estimatedDuration
                    created
                    updated
                    deleted
                    contentType
                    objectId
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(taskId) })
        if (res && res.task) {
            // Map backend contentType to frontend model names
            // eg 'ftpaccount' -> virtualServerFtpAccount
            const tasksWithMappedTypes = [res.task].map(task => ({
                ...task,
                objectId: parseInt(task.objectId), // uuid may not work?
                contentType: contentTypeMapping[task.contentType] || task.contentType,
            }))
            useRepo(this).save(tasksWithMappedTypes)
        }
        return res
    }

}
