import { defineNuxtPlugin } from '#app';
import { useRepo } from 'pinia-orm';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/index';
import { useNuxtApp } from '#app'
import Task from '@/data/models/Task'
import VirtualServer from '@/data/models/VirtualServer'
import Vm from '@/data/models/Vm'

export function initializeTaskUpdates() {
    console.log('Init plugins/nodeup-websocket-updates.js: task-updates');

    const nuxtApp = useNuxtApp();

    const token = localStorage.getItem('authToken');
    if (!token) return;

    if (window.ws_task_updates) {
        window.ws_task_updates.onclose = void (0);
        window.ws_task_updates.close();
    }
    window.ws_task_updates = new WebSocket(`wss://${nuxtApp.$config.public.vncProxyHost}:${nuxtApp.$config.public.vncProxyPort}/ws/task_updates?token=${token}`);

    window.ws_task_updates.onclose = function () {
        console.log("WebSocket disconnected. Attempting to reconnect...");
        // do not try to reconnect on dev environments
        if (!nuxtApp.$config.public.environment.includes('dev')) {
            setTimeout(() => initializeTaskUpdates(), 1000);
        }
    }

    window.ws_task_updates.onmessage = async function (event) {
        const store = useStore();
        const data = JSON.parse(event.data);

        if (data && data.tasks_updated) {
            let taskId = data.tasks_updated[0].task_id;
            await Task.apiFetchSingle(taskId);
        }

        if (data && data.tasks_progress) {
            let taskId = data.tasks_progress[0].task_id;
            let newProgress = parseFloat(data.tasks_progress[0]?.task_progress) ?? null;
            let task = useRepo(Task).find(taskId);
            if (!task) {
                await Task.apiFetchSingle(taskId);
            }
            useRepo(Task).where('id', taskId).update({
                'taskProgress': newProgress
            })
        }

        if (data && data.tasks_completed) {
            let taskId = data.tasks_completed[0].task_id;
            let task = Task.getItems({
                with: 'related',
                where: ['id', parseInt(taskId)]
            })[0]
            let msg = ''
            if (task?.contentType == 'virtualServerSubdomains') {
                let subdomain = task.related;
                if (subdomain) {
                    let virtualServer = useRepo(VirtualServer).find(subdomain.virtualServerId);
                    msg += 'Subdomain ' + subdomain.name + '.' + virtualServer?.domainName;
                }
            }
            if (task?.contentType == 'virtualServerEmails') {
                let email = task.related;
                if (email) {
                    msg += 'Email ' + email.address;
                }
            }
            if (task?.contentType == 'vpnUsers') {
                msg += 'VPN user';
            }
            if (task?.contentType == 'virtualservers') {
                msg += 'SSH service started.';
            } else {
                if (task?.taskType == 'UPDATING') {
                    msg += ' updated.';
                } else if (task?.taskType == 'CREATING') {
                    msg += ' created successfully.';
                }
            }
            if (msg) {
                store.setToastMessage({ message: msg, duration: 2000 });
            }
            await useRepo(Task).destroy(parseInt(taskId))
        }
    };

    document.addEventListener('GraphQLReady', () => {
        Task.apiFetchAll();
    });
}


export function initializeVmUpdates() {
    if (window.ws_vm_updates) {
        return
    }
    console.log('Init plugins/nodeup-websocket-updates.js: vm-updates');
    const token = localStorage.getItem('authToken');
    if (!token) return;
    const nuxtApp = useNuxtApp();
    const store = useStore();
    window.ws_vm_updates = new WebSocket(`wss://${nuxtApp.$config.public.vncProxyHost}:${nuxtApp.$config.public.vncProxyPort}/ws/vm_updates?token=${token}`);

    window.ws_vm_updates.onclose = function () {
        console.log("WebSocket disconnected. Attempting to reconnect...");
        setTimeout(() => initializeVmUpdates(), 1000);
    }

    window.ws_vm_updates.onmessage = async function (event) {
        const data = JSON.parse(event.data)
        if (data && data.vm_updated) {
            let vmUuid = data.vm_updated
            let vmData = useRepo(Vm).query().where('uuid', vmUuid).get()
            if (vmData && vmData.length > 0 && vmData[0].id) {
                await Vm.apiFetchSingle(vmData[0].id)
            } else {
                await Vm.apiFetchAll(store.project)
            }
        }
    }
}


export function closeWebsocketConnections() {
    if (window.ws_task_updates) {
        window.ws_task_updates.onclose = null;
        window.ws_task_updates.close();
        window.ws_task_updates = null;
    }

    if (window.ws_vm_updates) {
        window.ws_vm_updates.onclose = null;
        window.ws_vm_updates.close();
        window.ws_vm_updates = null;
    }

    console.log("WebSockets disconnected");
}


export default defineNuxtPlugin(nuxtApp => {
    console.log('Init plugins/nodeup-websocket-updates.js');

    const router = useRouter();
    router.beforeEach((to, from, next) => {
        if (to.path.startsWith('/vms')) {
            initializeVmUpdates()
        }
        next()
    });

    // afterLogin already initializes this connection, so we don't need to do it again
    // initializeTaskUpdates();
});
